import { atom, noWait, selector, selectorFamily } from 'recoil';
import { fetcher } from '../../utils/api';

export const atomCurrencies = atom({
	key: 'currencies',
	default: selector({
		key: 'currencies/selector',
		get: async () => {
			return await fetcher('/currencies');
		},
	}),
});

export const currencyByUid = selectorFamily({
	key: 'currencyByUid',
	get:
		(uid: string) =>
		async ({ get }) => {
			const { contents: currencies, state } = await get(noWait(atomCurrencies));

			if (state === 'hasValue') {
				return currencies.find((currency) => currency.uid === uid);
			}

			return [];
		},
});
