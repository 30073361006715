import { Loader } from '@/components/spinners/loader';
import { Popover, Table } from 'antd';
import JSConfetti from 'js-confetti';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilStateLoadable, useRecoilValueLoadable } from 'recoil';
import { toast } from 'sonner';
import { atomCurrencies } from '../../../atoms/currencies';
import { selectedSpaceAtom } from '../../../atoms/spaces';
import { _me } from '../../../atoms/users';
import { EditableCell } from '../../../shared/components/items/EditableCell';
import { scrollToElement } from '../../../utils/helpers';
import { Icon } from '../../icons/components/IconLoader';
import { tagsGroupsAtom } from '../../tags/atoms/tags';
import { cataloguePricesBySelectedAtom } from '../atoms';
import { createBasePrice, deletePricing, updateBasePrice } from '../utils/api';
import { CurrencyRender } from './CurrencyRender';
import { PriceInput } from './PriceInput';
import { TagsRender } from './TagsRender';

export const PricingTable = ({ readOnly, selectedRows, onSelect }: any) => {
	const [{ contents: prices, state }, setPrices] = useRecoilStateLoadable(cataloguePricesBySelectedAtom);
	const { contents: currencies } = useRecoilValueLoadable(atomCurrencies);
	const { contents: selectedSpace } = useRecoilValueLoadable(selectedSpaceAtom);
	const [expanded, setExpanded] = useState<any[]>([]);

	const { contents: tagGroups, state: tagState } = useRecoilValueLoadable(tagsGroupsAtom);

	const handleRowDelete = (record) => {
		toast.promise(deletePricing(record.uid), {
			loading: 'Deleting price',
			success: () => {
				setPrices((prev) => prev.filter((price) => price.uid !== record.uid));
				return 'Price has been deleted';
			},
			error: 'Failed to delete price',
		});
	};

	const tagColumns = useMemo(() => {
		if (tagState !== 'hasValue') return [];
		return (selectedSpace?.config?.pricing?.tag_groups || [])
			?.map((group) => {
				const currentTagGroup = tagGroups.find((g) => g.uid === group);

				if (currentTagGroup === undefined) return null;

				return {
					key: currentTagGroup.uid,
					dataIndex: currentTagGroup.uid,
					title: <div className="capitalize">{currentTagGroup.name}</div>,
					render: (_, record: any) => {
						if (currentTagGroup.uid !== 'other' && currentTagGroup.tags.length === 0) {
							return <div className="text-xs italic text-gray-300">No tags in group</div>;
						}

						if (readOnly) return <TagsRender source={record.uid} editable={false} group={currentTagGroup.uid} />;

						return (
							<EditableCell record={record} className="min-h-[40px]">
								{({ editable, selected }) => {
									return <TagsRender source={record.uid} editable={true} selected={selected} group={currentTagGroup.uid} />;
								}}
							</EditableCell>
						);
					},
				};
			})
			.filter(Boolean);
	}, [selectedSpace, tagState]);

	const updateRow = (record, key, value) => {
		toast.promise(updateBasePrice(record.uid, { [key]: value }), {
			loading: 'Updating price',
			success: () => {
				return 'Price has been updated';
			},
			error: 'Failed to update price',
		});
	};

	const toggleExpand = (record) => {
		if (expanded.includes(record.uid)) {
			setExpanded((prev) => prev.filter((uid) => uid !== record.uid));
		} else {
			setExpanded((prev) => [...prev, record.uid]);
		}
	};

	const _columns = useMemo(() => {
		return [
			...tagColumns,
			{
				key: 'min_price',
				dataIndex: 'price',
				title: 'Min.',
				width: 120,
				className: 'text-left',
				render: (_, record) => {
					if (readOnly) return <div className="text-left">{record.price[0] / 100}</div>;
					return (
						<EditableCell reference={record.uid} record={record}>
							{({ editable, data, setData }) => {
								const [price, max_price] = data.price || [0, 0];
								if (max_price < price && price !== 0) {
									setData((prev) => ({ ...prev, price: [price, price], price_error: [false, true] }));
									updateRow(record, 'max', price);
								}
								return (
									<PriceInput
										defaultValue={price / 100}
										focus={editable}
										onChange={(value) => {
											setData((prev) => ({ ...prev, price: [value, prev.price[1]], price_error: [false, false] }));
											updateRow(record, 'min', value);
										}}
									/>
								);
							}}
						</EditableCell>
					);
				},
			},
			{
				key: 'max_price',
				dataIndex: 'price',
				title: 'Max.',
				width: 120,
				render: (_, record) => {
					if (readOnly) return <div className="text-right">{record.price[1] / 100}</div>;
					return (
						<EditableCell reference={record.uid} record={record}>
							{({ editable, data, setData }) => {
								const [min_price, price] = data.price || [0, 0];

								if (min_price > price && price !== 0) {
									setData((prev) => ({ ...prev, price: [min_price, min_price], price_error: [false, true] }));
									updateRow(record, 'max', min_price);
								}

								return (
									<div className="relative flex flex-col">
										<PriceInput
											defaultValue={price / 100}
											focus={editable}
											onChange={(value) => {
												setData((prev) => ({ ...prev, price: [prev.price[0], value], price_error: [false, false] }));
												updateRow(record, 'max', value);
											}}
										/>
										{data?.price_error?.[1] && (
											<Popover
												className="absolute -right-2 top-1/2 -translate-y-1/2"
												content={
													<div className="text-xs text-orange-500">
														Max price cannot be lower than min price, so we changed it for you!
													</div>
												}
											>
												<Icon icon={'faExclamationTriangle'} className="text-orange-500" />
											</Popover>
										)}
									</div>
								);
							}}
						</EditableCell>
					);
				},
			},
			{
				key: 'currency',
				dataIndex: 'currency',
				title: 'Currency',
				width: 120,
				render: (_, record) => {
					if (readOnly) return <CurrencyRender currency_uid={record.currency} editable={false} />;
					return (
						<EditableCell reference={record.uid} record={record}>
							{({ editable, setData, data }) => {
								return (
									<CurrencyRender
										currency_uid={data.currency || record.currency}
										editable={editable}
										onChange={(value) => {
											setData((prev) => ({ ...prev, currency: value }));
											updateRow(record, 'currency', value);
										}}
									/>
								);
							}}
						</EditableCell>
					);
				},
			},
			{
				key: 'trash',
				dataIndex: 'trash',
				title: '',
				render: (_, record) => {
					return <Icon icon={'faTrash'} size="sm" className="cursor-pointer text-red-500" onClick={() => handleRowDelete(record)} />;
				},
			},
			// {
			// 	key: 'eye',
			// 	dataIndex: 'eye',
			// 	title: '',
			// 	render: (_, record) => {
			// 		return (
			// 			<Icon
			// 				icon={'faEye'}
			// 				size="sm"
			// 				className="cursor-pointer"
			// 				onClick={() => toggleExpand(record)}
			// 				onKeyDown={() => toggleExpand(record)}
			// 			/>
			// 		);
			// 	},
			// },
			// Table.EXPAND_COLUMN,
		];
	}, [tagColumns, expanded, readOnly]);

	const tableRef = useRef<any>(null);
	const isEventListenerAdded = useRef(false);

	const handleOnSuccess = async () => {
		toast.promise(
			createBasePrice({
				price: [0, 0],
				currency: currencies.find((c) => c.prefix === selectedSpace.currency).uid as string,
				tags: [],
			}),
			{
				loading: 'Creating new price',
				success: (data) => {
					setPrices((prev) => [...prev, data]);
					setTimeout(() => {
						if (tableRef.current) {
							if (!!_me?.celebration_mode) {
								new JSConfetti().addConfetti({
									emojis: ['💵', '💶', '💷', '💰'],
									emojiSize: 30,
									confettiNumber: 200,
								});
							}
							const rows = tableRef.current?.querySelectorAll('.ant-table-row');
							if (rows && rows.length > 0) {
								const lastRow = rows[rows.length - 1];
								const firstCell = lastRow.querySelector('.ant-table-cell [tabindex="0"]');
								firstCell?.focus();
								scrollToElement(firstCell);
							}
						}
					}, 0);

					return 'Price has been created';
				},
				error: 'Failed to create new price',
			}
		);
	};

	function handleKeyboardEvent(e: KeyboardEvent): void {
		const isCtrlOrCmdPressed = (e.ctrlKey && !e.metaKey) || (e.metaKey && !e.ctrlKey);
		if (isCtrlOrCmdPressed && e.shiftKey) {
			if (e.key === 'a') {
				e.preventDefault();
				e.stopPropagation();
				handleOnSuccess();
			}
		}
	}

	const addEventListener = () => {
		if (!isEventListenerAdded.current) {
			window.addEventListener('keydown', handleKeyboardEvent);
			isEventListenerAdded.current = true;
		}
	};

	const removeEventListener = () => {
		if (isEventListenerAdded.current) {
			window.removeEventListener('keydown', handleKeyboardEvent);
			isEventListenerAdded.current = false;
		}
	};

	useEffect(() => {
		addEventListener();

		return () => {
			removeEventListener();
		};
	}, [addEventListener, removeEventListener]);

	const getRowClassName = (_) => {
		let className = '';
		if (onSelect) {
			className += 'cursor-pointer ';
		}

		return className;
	};

	if (state === 'loading') return <Loader />;
	if (state === 'hasError') return <>Something went wrong</>;

	return (
		<div ref={tableRef}>
			<Table
				dataSource={prices?.map((price) => ({ ...price, onExpand: toggleExpand }))}
				columns={_columns}
				// expandable={{
				// 	expandedRowRender: PriceConditionsCallback,
				// 	expandedRowKeys: expanded,
				// 	expandIcon: () => {
				// 		return null;
				// 	},
				// }}
				rowKey="uid"
				scroll={{ x: 'max-content' }}
				pagination={false}
				rowClassName={(record) => `${getRowClassName(record)}`}
				onRow={(record) => {
					return {
						onClick: () => {
							if (onSelect) {
								onSelect?.(record);
							}
						},
					};
				}}
				rowSelection={
					onSelect
						? {
								columnTitle: () => {
									return <></>;
								},
								renderCell: (_, record) => {
									return (
										<>
											{selectedRows?.includes(record.uid) ? (
												<button
													onClick={() => {}}
													className="flex w-fit items-center gap-2 rounded-md bg-green-100 px-4 py-2 text-green-600"
												>
													<Icon icon={'faCheck'} />
													<p className="text-xs">Selected</p>
												</button>
											) : (
												<button
													onClick={() => {}}
													className="flex w-fit items-center gap-2 rounded-md bg-gray-100 px-8 py-2 text-gray-600"
												>
													<p className="text-xs">Select</p>
												</button>
											)}
										</>
									);
								},
						  }
						: undefined
				}
				footer={
					!readOnly
						? () => {
								return (
									<div
										tabIndex={0}
										className="flex cursor-pointer items-center gap-1 text-xs"
										onKeyDown={(e) => {
											if (e.code === 'Enter') {
												handleOnSuccess();
											}
										}}
										onClick={() => {
											handleOnSuccess();
										}}
									>
										<Icon icon={'faCirclePlus'} /> Add row
									</div>
								);
						  }
						: null
				}
			/>
		</div>
	);
};
