import { useRef } from 'react';
import { useRecoilStateLoadable } from 'recoil';
import { toast } from 'sonner';
import { AddTagButton } from '../../tags/components/AddTagButton';
import { TagsViewer } from '../../tags/components/TagsViewer';
import { getTagsBySources } from '../../tags/utils/getTagsBySources';
import { pricingTags } from '../atoms';

export const TagsRender = ({ source, editable = true, selected, group }: { source: string; editable: boolean; selected?: any; group?: any }) => {
	const [{ contents: _tags, state }, setTags] = useRecoilStateLoadable(pricingTags(source));

	const containerRef = useRef(null);

	const fetchTags = async () => {
		const tags = await getTagsBySources([source]);
		setTags(tags[source]);
	};

	if (state !== 'hasValue') return <></>;

	const tagsByGroup =
		_tags?.filter((tag) => (group === 'other' && tag.groups.length === 0) || (group ? tag.groups.some((g) => g.uid === group) : true)) || [];

	return (
		<div className="flex gap-2" ref={containerRef}>
			{editable && (tagsByGroup.length as Number) === 0 && (
				<AddTagButton
					open={selected}
					group={group}
					onSelect={(tag) => {
						setTags((prev) => {
							if (prev?.find((item) => item.uid === tag.uid)) return prev;
							return [...(prev || []), tag];
						});
						toast.success('Tag added successfully');
					}}
					source={source}
					onClose={() => {
						containerRef.current?.focus();
					}}
				/>
			)}
			{editable && (tagsByGroup.length as Number) !== 0 && (
				<AddTagButton
					open={selected}
					group={group}
					hoverEffect={true}
					onSelect={(tag) => {
						setTags((prev) => {
							if (prev?.find((item) => item.uid === tag.uid)) return prev;
							return [...(prev || []), tag];
						});
						toast.success('Tag added successfully');
					}}
					source={source}
					onClose={() => {
						containerRef.current?.focus();
					}}
				/>
			)}

			<TagsViewer
				source={editable ? source : null}
				onRemove={
					editable
						? () => {
								fetchTags();
						  }
						: undefined
				}
				shownAmount={3}
				tags={tagsByGroup}
			/>
		</div>
	);
};
