import { isEmpty } from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { atomFamily, useRecoilState } from 'recoil';

export const EditableCellContainer = atomFamily({
	key: 'EditableCellContainer',
	default: {},
});

export const EditableCell = ({
	children,
	record,
	className,
	reference,
}: {
	children: ({ editable, data, selected, setData }) => ReactNode;
	record: any;
	className?: string;
	reference?: string;
}) => {
	const [editable, setEditable] = useState<boolean>(false);
	const [isSelected, setIsSelected] = useState<boolean>(false);
	const [data, setData] = useRecoilState<any>(EditableCellContainer(reference));

	useEffect(() => {
		if (isEmpty(data) && reference) {
			setData(record);
		}
	}, [record, reference, setData, data]);

	const containerRef = useRef(null);
	const leaveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const handleFocus = () => {
		if (leaveTimeoutRef.current) {
			clearTimeout(leaveTimeoutRef.current);
			leaveTimeoutRef.current = null;
		}
		setEditable(true);
	};

	const delayedSetEditableFalse = () => {
		leaveTimeoutRef.current = setTimeout(() => {
			setEditable(false);
			setIsSelected(false);
		}, 200);
	};

	const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
		if (containerRef.current && !containerRef.current.contains(event.relatedTarget as Node)) {
			delayedSetEditableFalse();
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.target === containerRef.current) {
			const key = event.key || (event as any).keyCode;

			if (key === 'Enter' || key === ' ') {
				setIsSelected(true);
			}

			if (key === 'Escape' && isSelected) {
				setIsSelected(false);
			}
		}
	};

	return (
		<div
			onMouseEnter={handleFocus}
			onMouseLeave={delayedSetEditableFalse}
			onFocus={handleFocus}
			onBlur={handleBlur}
			className={'flex w-full items-center gap-2 ' + className || ''}
			tabIndex={0}
			onKeyDown={handleKeyDown}
			ref={containerRef}
		>
			{children({ editable, data, selected: isSelected, setData })}
		</div>
	);
};
