import { sortBy } from 'lodash';
import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { toast } from 'sonner';
import { getTagsBySources } from '../../tags/utils/getTagsBySources';
import { addPricesToCatalogue, getCatalogues, getCataloguesPrices, updateCatalogue } from '../utils/api';

export const pricingTags = atomFamily({
	key: 'pricingTags',
	default: (uid: string) =>
		selector({
			key: `pricingTags/${uid}`,
			get: async () => {
				const getTags = await getTagsBySources([uid]);
				return getTags[uid] ?? [];
			},
		}),
});

export const cataloguesAtom = atom({
	key: 'getCatalogues',
	default: selector({
		key: 'getCatalogues/Default',
		get: async () => {
			return (await getCatalogues()) ?? [];
		},
	}),
});

export const cataloguePricesAtom = atomFamily({
	key: 'cataloguePricesAtom',
	default: (uid: string) =>
		selector({
			key: `cataloguePricesAtom/${uid}`,
			get: async () => {
				return (await getCataloguesPrices(uid)) ?? [];
			},
		}),
});

export const selectedCatalogueUidAtom = atom({
	key: 'selectedCatalogueUidAtom',
	default: undefined,
});

export const catalogueDataAtom = atomFamily({
	key: 'catalogueDataAtom',
	default: selectorFamily({
		key: 'catalogueDataAtom/Default',
		get:
			(catalogUid: string) =>
			async ({ get }) => {
				const catalogues = get(cataloguesAtom);

				return catalogues.find(({ uid }) => uid === catalogUid) || {};
			},
	}),
});

export const selectedCatalogueDataAtom = selector({
	key: 'selectedCatalogueDataAtom',
	get: ({ get }) => {
		const selectedCatalogue = get(selectedCatalogueUidAtom);
		return get(catalogueDataAtom(selectedCatalogue));
	},
	set: ({ set, get }, newValue) => {
		const selectedCatalogue = get(selectedCatalogueUidAtom);
		if (selectedCatalogue) {
			set(catalogueDataAtom(selectedCatalogue), newValue);
			toast.promise(updateCatalogue(selectedCatalogue, newValue), {
				loading: 'Updating catalogue...',
				success: 'Catalogue updated',
				error: 'Error updating catalogue',
			});
		}
	},
});

export const cataloguePricesBySelectedAtom = selector({
	key: 'cataloguePricesBySelectedAtom',
	get: async ({ get }) => {
		const selectedCatalogue = get(selectedCatalogueUidAtom);
		if (selectedCatalogue) {
			const getCataloguesPrices = get(cataloguePricesAtom(selectedCatalogue));
			return sortBy(getCataloguesPrices ?? [], 'created', 'asc');
		}

		return [];
	},
	set: ({ set, get }, newValue) => {
		const selectedCatalogue = get(selectedCatalogueUidAtom);

		if (selectedCatalogue) {
			set(cataloguePricesAtom(selectedCatalogue), newValue);

			addPricesToCatalogue(
				selectedCatalogue,
				(newValue as any).map(({ uid }) => uid)
			);
		}
	},
});
